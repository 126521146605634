import { AxiosRequestConfig } from 'axios';

import { IGetFilesParams } from '../common/interfaces/files';
import httpClient from '../http-client';
import UploadedFile, {
  DeleteFileResponse,
  FileData,
  UrlSignedResponse,
} from '../models/from-api-response/file';

const getImages = async (
  page: number,
  quantity = 6,
  fileName?: string,
): Promise<any> => {
  const images = (
    await httpClient.get(
      `files?type=image&limit=${quantity}&offset=${page + 1}${
        fileName ? '&search=' + fileName : ''
      }&permission=public`,
    )
  ).data;

  return images;
};

const getFiles = async (params: IGetFilesParams): Promise<any> => {
  if (!params.limit) {
    params['limit'] = 6;
  }

  if (!params.permission) {
    params['permission'] = 'public';
  }

  const files = (await httpClient.get('files', { params })).data;

  return files;
};

const searchFile = async (contentId: string) => {
  const content = await httpClient.get(`files/${contentId}?permission=public`);
  return content.data;
};

const uploadFile = async (
  formData: FormData,
  config?: AxiosRequestConfig,
): Promise<UploadedFile> => {
  const uploadFileResponse = (
    await httpClient.post(`files`, formData, {
      ...config,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  ).data;

  return uploadFileResponse;
};

const updateUploadFile = async (
  contentId: string,
  formData: FormData,
): Promise<UploadedFile> => {
  formData.append('permission', 'public');
  const uploadFileResponse = (
    await httpClient.put(`files/${contentId}`, formData)
  ).data;

  return uploadFileResponse;
};

const deleteFile = (fileId: string): Promise<DeleteFileResponse> => {
  return new Promise((resolve, reject) => {
    httpClient
      .delete(`files/${fileId}`)
      .then(response => {
        resolve(response as unknown as FileData);
      })
      .catch(error => {
        reject(error.response.data);
      });
  });
};

const uploadMainFile = async (formData: FormData): Promise<UploadedFile> => {
  formData.append('permission', 'public');
  const uploadFileResponse = (
    await httpClient.post(`content/file`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  ).data;

  return uploadFileResponse;
};

const webHook = async (media: any): Promise<any> => {
  const data = (await httpClient.post(`webhooks`, media)).data;

  return data;
};

const getSignedUrl = async (contentType: string, path: string) => {
  return (
    await httpClient.post<UrlSignedResponse>('files/url-signed', {
      content_type: contentType,
      path: `scorm/sebrae/${path} `,
    })
  ).data;
};

export {
  deleteFile,
  getFiles,
  getImages,
  getSignedUrl,
  searchFile,
  updateUploadFile,
  uploadFile,
  uploadMainFile,
  webHook,
};
