import {
  AddCircle,
  Article,
  Attachment,
  BlurLinear,
  Delete,
  DeleteForever,
  FileDownload,
  FilterAlt,
  InsertPhoto,
  Lyrics,
  ModeEdit,
  Save,
  Videocam,
  ViewList,
} from '@mui/icons-material';
import ArticleIcon from '@mui/icons-material/Article';
import {
  AppBar,
  Box,
  Button,
  ButtonGroup,
  CardActionArea,
  CardMedia,
  IconButton,
  Modal,
  Toolbar,
  Typography,
} from '@mui/material';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import React, { useCallback, useEffect, useRef } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import Swal from 'sweetalert2';

import { IGetFilesParams } from '../../../common/interfaces/files';
// Helpers
import toast from '../../../helpers/toast';
import { useDownloadFile } from '../../../hooks';
import { getCategories } from '../../../services/categorization';
import {
  deleteFile,
  getFiles,
  searchFile,
  updateUploadFile,
  uploadFile,
} from '../../../services/files';
import CategoryDropDown from '../../CategoryDropDown';
import Container from '../../Drag/Container';
import * as styles from './styles';

const Input = styled('input')({
  display: 'none',
});

interface IFileComplementaryProps {
  setValue: any;
  content: any;
  view: any;
  setFilesLms?: any;
  isCompany?: any;
  titleCompany?: any;
  control?: any;
  children?: any;
  isLms?: boolean;
  permission: 'public' | 'all' | 'restricted' | 'unlisted';
}

export default function FilesComplementary(props: IFileComplementaryProps) {
  const {
    setValue: setValueProps,
    content,
    view,
    setFilesLms,
    isCompany,
    titleCompany,
    control: controlProps,
    children,
    permission,
    isLms,
  } = props;

  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [files, setFiles] = React.useState<FileList>();
  const [fileName, setFileName] = React.useState<string>('');
  const [fileComplementary, setFileComplementary] = React.useState<any>([]);
  const [fileToolTip, setFileToolTip] = React.useState<string>('');
  const [modalPreview, setModalPreview] = React.useState<
    string | ArrayBuffer
  >();
  const [collectionModalOpen, setCollectionModalOpen] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [filter, setFilter] = React.useState<string>('');
  const [typeFilter, setTypeFilter] = React.useState<string>('');
  const [imagePage, setImagePage] = React.useState(1);
  const [images, setImages] = React.useState<any[]>([]);
  const [isEditing, setIsEditing] = React.useState(false);
  const [category, setCategory] = React.useState<any[]>([]);
  const [midiaItem, setMidiaItem] = React.useState<any>({});
  const [skill, setSkill] = React.useState<any>();
  const [addList, setAddList] = React.useState<boolean>(false);
  const [backCategories, setBackCategories] = React.useState<string[]>([]);
  const [openFilter, setOpenFilter] = React.useState<boolean>(false);

  const ordersToRefFileComplementary: any = useRef();
  ordersToRefFileComplementary.current = fileComplementary;

  const refSkill: any = useRef();
  refSkill.current = skill;

  const location = useLocation();

  React.useEffect(() => {
    getCategories().then((data: any) => {
      setSkill({ ...props, category: data.skills, setState: setCategory });
    });
  }, []);

  const handleCollectionsClick = () => {
    const params: IGetFilesParams = {
      offset: imagePage === 0 ? imagePage + 1 : imagePage,
      permission,
    };

    setCollectionModalOpen(true);
    setLoading(true);
    getFiles(params).then((element: any) => {
      setImages(element?.data);
      setLoading(false);
    });
  };

  const { getValues, setValue, control, handleSubmit } = useForm();

  const fillFile = () => {
    const editing = content;

    if (editing) {
      setFileName(editing.name);
      setValueProps('complement_files', editing.complement_files);
      setFileComplementary(editing.complement_files);
    }
  };

  const getFile = async (formData: any) => {
    searchFile(formData).then(data => {
      setFileName(data.original_name);
      setValueProps('file_id', data.file_id);
    });
  };

  const deleteMain = async () => {
    setValueProps('complement_files', null);
    setFileComplementary([]);
    setFiles(undefined);
    setFileName('');
  };

  const deleteImage = async itemImage => {
    const params: IGetFilesParams = {
      offset: imagePage === 0 ? imagePage + 1 : imagePage,
      permission,
    };

    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Tem certeza que deseja remover a mídia?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await deleteFile(itemImage.file_id);
          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Mídia excluído com sucesso!',
          }).then(async res => {
            if (res.isConfirmed) {
              setCollectionModalOpen(true);
              setLoading(true);
              getFiles(params).then((element: any) => {
                setImages(element?.data);
                setLoading(false);
              });
            }
          });
        } catch (error: any) {
          Swal.fire({
            title: error.message,
            icon: 'error',
          }).finally(() => {
            setCollectionModalOpen(true);
          });
        }
      }
    });
  };

  useEffect(() => {
    if (content) {
      fillFile();
      if (content.file_id) {
        getFile(content.file_id);
      }
    }
  }, [content]);

  useEffect(() => {
    const complementFilesId: any[] = [];
    fileComplementary.forEach((files: any) => {
      complementFilesId.push(files.file_id);
    });
    setValueProps('complement_files', complementFilesId);
    setFilesLms && setFilesLms(fileComplementary);
  }, [fileComplementary]);

  function onSubmit(data: any) {
    if (isEditing) {
      handleSaveUpdate(data);
    } else {
      const formData = new FormData();
      if (files !== undefined) {
        const file = files[0];
        const blob = file.slice(0, file.size, file.type);
        const fileSplited = files[0].name.split('.');
        const newFile = new File(
          [blob],
          fileName + '.' + fileSplited[fileSplited.length - 1],
          { type: file.type },
        );

        formData.append('file', newFile);
        formData.append('tooltip', fileToolTip);

        if (category.length > 1) {
          let categoryCopy: any[] = [];
          categoryCopy = category;
          categoryCopy.forEach(el =>
            formData.append('categories[]', el.category_id),
          );
        } else {
          toast({
            message: 'É necessario escolher apenas uma categoria filha.',
            type: 'warning',
          });
        }
        if (category.length > 1) {
          //TODO: REVER ESSA GAMBIS
          let permissionType = 'public';
          if (
            location.pathname.includes('serie') ||
            location.pathname.includes('course')
          ) {
            permissionType = 'restricted';
          }

          if (location.pathname.includes('exclusive')) {
            permissionType = 'unlisted';
          }

          formData.append('permission', permissionType);
          uploadFile(formData).then(data => {
            setFileComplementary([...fileComplementary, data]);
            setModalOpen(false);
            setCollectionModalOpen(false);
            Swal.fire({
              title: 'Mídia criado com sucesso',
              icon: 'success',
            });
          });
          setModalOpen(false);
          setCollectionModalOpen(false);
        }
      }
    }
  }

  const handleDeleteItem = item => {
    const updatedFileComplementary =
      ordersToRefFileComplementary.current.filter(
        e => e.file_id !== item.file_id,
      );
    setFileComplementary(updatedFileComplementary);
  };

  const handleSaveUpdate = (data: any) => {
    let body: any = {};
    const setCategories: any[] = [];

    if (category.length > 1) {
      let categoryCopy: any[] = [];
      categoryCopy = category;
      categoryCopy.forEach(el => setCategories.push(el.category_id));
    } else {
      toast({
        message: 'É necessário conter categoria com o código sebrae.',
        type: 'warning',
      });
    }

    body = { categories: setCategories, tooltip: getValues('tooltip') };

    if (category.length > 1) {
      updateUploadFile(midiaItem.file_id, body).then(resp => {
        if (addList) {
          setFileComplementary([...fileComplementary, resp]);
        }
        Swal.fire({
          title: 'Mídia alterada com sucesso',
          icon: 'success',
        });
        setModalOpen(false);
        setCollectionModalOpen(false);
      });
      setModalOpen(false);
      setCollectionModalOpen(false);
    }
  };

  const addCategory = useCallback(async (midiaItem: any) => {
    setMidiaItem(midiaItem);
    setIsEditing(true);
    setAddList(true);
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'A mídia não contém categoria vinculada, você deve adicionar.',
      showCancelButton: false,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          setModalOpen(true);
          setFileName(midiaItem.original_name);
          setValue('file_name', midiaItem.original_name);
          setValue('tooltip', midiaItem.tooltip);
          setFileToolTip(midiaItem.tooltip);
          setModalPreview(midiaItem.reference);
          setFiles(midiaItem.reference);
        } catch (error) {
          toast({
            message: 'algo deu errado',
            type: 'warning',
          });
        }
      }
    });
  }, []);

  const editCategory = useCallback(async (midiaItem: any) => {
    setMidiaItem(midiaItem);
    setIsEditing(true);
    setAddList(false);
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Deseja editar a mídia ' + midiaItem.original_name + ' ?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          setFileName(midiaItem.original_name);
          setValue('file_name', midiaItem.original_name);
          setFileToolTip(midiaItem.tooltip);
          setValue('tooltip', midiaItem.tooltip);
          setModalPreview(midiaItem.reference);
          setFiles(midiaItem.reference);
          setSkill({
            ...refSkill.current,
            content: { categories: midiaItem.categories },
          });
          setModalOpen(true);
        } catch {
          toast({
            message: 'algo deu errado',
            type: 'warning',
          });
        }
      }
    });
  }, []);

  const cleanModalState = async () => {
    setFileToolTip('');
    setFileName('');
    setValue('tooltip', null);
    setValue('file_name', null);
    setSkill({ ...refSkill.current, content: undefined });
    setModalPreview(undefined);
    setFiles(undefined);
  };

  function getModalStyle() {
    const top = 25;

    return {
      top: `${top}%`,
      margin: 'auto',
      width: '85%',
      height: '95%',
      backgroundColor: 'white',
      marginTop: '1vw',
      overflow: 'hidden',
      overflowY: 'scroll',
    } as React.CSSProperties;
  }

  function handleSearchFilter(type?: string) {
    const params: IGetFilesParams = {
      offset: imagePage === 0 ? imagePage + 1 : imagePage,
      limit: 6,
      permission,
    };

    if (filter.length) params['search'] = filter;
    if (type?.length) params['type'] = type;

    setImagePage(1);
    setLoading(true);
    getFiles(params).then(images => {
      setImages(images?.data);
      setLoading(false);
    });
  }

  const { downloadFile } = useDownloadFile();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '22px',
        width: '100%',
      }}
    >
      {!isCompany && (
        <Typography
          variant="h4"
          color={isLms ? 'var(--primary-color)' : 'black'}
        >
          {!isLms ? 'Arquivos complementares' : 'Material de Apoio'}
        </Typography>
      )}

      <styles.Label style={{ margin: !isCompany ? '1rem' : '-0.5rem' }}>
        {titleCompany}
      </styles.Label>

      {!isLms && (
        <DndProvider backend={HTML5Backend}>
          <Container
            type={'fileComplementary'}
            item={fileComplementary}
            setItem={setFileComplementary}
            onChangeDeleteItem={handleDeleteItem}
            view={view}
          />
        </DndProvider>
      )}

      {children !== undefined && isLms ? children : <></>}

      {fileComplementary.length === 0 && !isLms ? (
        <span
          style={{ color: 'disable', marginTop: '1rem', marginBottom: '1rem' }}
        >
          Nenhum arquivo selecionado
        </span>
      ) : (
        <></>
      )}

      <ButtonGroup
        style={{ boxShadow: '0px 0px' }}
        variant="contained"
        size="small"
      >
        <Button
          disabled={props.view}
          onClick={handleCollectionsClick}
          startIcon={<ViewList />}
          style={{
            marginRight: 1,
          }}
        >
          <span>Listar</span>
        </Button>
        <Button
          disabled={view}
          startIcon={<AddCircle />}
          onClick={() => {
            cleanModalState();
            setModalOpen(true);
            setIsEditing(false);
          }}
          style={{
            backgroundColor: '#008d4c',
            marginRight: 1,
          }}
        >
          <span>Adicionar novo</span>
        </Button>
        <Button
          disabled={view}
          onClick={() => {
            deleteMain();
          }}
          startIcon={<Delete />}
          style={{
            backgroundColor: '#d73925',
            marginRight: 1,
            display: !isLms ? 'flex' : 'none',
          }}
        >
          <span>Remover Todos</span>
        </Button>
      </ButtonGroup>

      {!isCompany && <></>}
      {children !== undefined && !isLms ? children : <></>}

      <Modal
        open={collectionModalOpen}
        onClose={() => setCollectionModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ alignItems: 'center', justifyContent: 'center' }}
      >
        <Box style={getModalStyle()}>
          <styles.column>
            <AppBar
              position="static"
              style={{ marginBottom: '1rem' }}
              sx={{ bgcolor: '#1565c0', height: '3.5rem' }}
            >
              <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  Mídia
                </Typography>
                <Button
                  onClick={() => setCollectionModalOpen(false)}
                  color="inherit"
                >
                  x
                </Button>
              </Toolbar>
            </AppBar>
            <div>
              <Button
                disabled={isEditing}
                variant="contained"
                size="small"
                component="span"
                startIcon={<FilterAlt />}
                style={{
                  backgroundColor: '#1565c0',
                  marginLeft: '0.5rem',
                  marginBottom: '1rem',
                }}
                onClick={() =>
                  openFilter ? setOpenFilter(false) : setOpenFilter(true)
                }
              >
                <span>Filtros</span>
              </Button>
            </div>
            {openFilter && (
              <>
                <span style={{ marginLeft: '0.5rem' }}>Por tipo:</span>
                <Box
                  marginBottom={'0.5rem'}
                  marginLeft={'0.5rem'}
                  sx={{
                    display: 'flex',
                    '& > *': {
                      m: 1,
                    },
                  }}
                >
                  <ButtonGroup
                    size="small"
                    variant="contained"
                    aria-label="outlined primary button group"
                    style={{ boxShadow: '0px 0px 0px' }}
                  >
                    <Button
                      startIcon={<Lyrics />}
                      style={{
                        marginRight: 2,
                        backgroundColor:
                          typeFilter === 'audio' ? '#3630b1' : '#8f8b8b',
                      }}
                      onClick={() => {
                        handleSearchFilter('audio');
                        setTypeFilter('audio');
                      }}
                    >
                      Áudio
                    </Button>
                    <Button
                      startIcon={<Article />}
                      style={{
                        marginRight: 2,
                        backgroundColor:
                          typeFilter === 'application' ? '#3630b1' : '#8f8b8b',
                      }}
                      onClick={() => {
                        handleSearchFilter('application');
                        setTypeFilter('application');
                      }}
                    >
                      Documentos
                    </Button>
                    <Button
                      startIcon={<InsertPhoto />}
                      style={{
                        marginRight: 2,
                        backgroundColor:
                          typeFilter === 'image' ? '#3630b1' : '#8f8b8b',
                      }}
                      onClick={() => {
                        handleSearchFilter('image');
                        setTypeFilter('image');
                      }}
                    >
                      Imagem
                    </Button>
                    <Button
                      startIcon={<Videocam />}
                      style={{
                        marginRight: 2,
                        backgroundColor:
                          typeFilter === 'video' ? '#3630b1' : '#8f8b8b',
                      }}
                      onClick={() => {
                        handleSearchFilter('video');
                        setTypeFilter('video');
                      }}
                    >
                      Vídeo
                    </Button>
                    <Button
                      startIcon={<BlurLinear />}
                      style={{
                        marginRight: 2,
                        backgroundColor:
                          typeFilter === '' ? '#3630b1' : '#8f8b8b',
                      }}
                      onClick={() => {
                        handleSearchFilter('');
                        setTypeFilter('');
                      }}
                    >
                      Todos
                    </Button>
                  </ButtonGroup>
                </Box>
                <span style={{ marginLeft: '0.5rem' }}>Por nome:</span>
                <styles.InputText
                  style={{ marginLeft: '0.5rem' }}
                  placeholder="Filtrar por nome"
                  onClick={event => {
                    event.stopPropagation();
                  }}
                  onChange={(event: any) => {
                    setLoading(true);
                    const params: IGetFilesParams = {
                      offset: imagePage === 0 ? imagePage + 1 : imagePage,
                      type: typeFilter,
                      limit: 6,
                      permission,
                    };

                    if (event.target.value !== '') {
                      params['search'] = event?.target.value;
                      setFilter(event.target.value);
                      getFiles(params).then(images => {
                        setImages(images?.data);
                      });
                      setImagePage(1);
                    } else {
                      getFiles(params).then(images => {
                        setImages(images?.data);
                      });
                      setFilter('');
                    }
                    setLoading(false);
                  }}
                />
              </>
            )}

            {!loading ? (
              <>
                <styles.row>
                  {images.slice(0, images.length / 2).map(image => {
                    return (
                      <Card
                        key={image.reference}
                        sx={{ width: 150, margin: 'auto', marginTop: '0.5rem' }}
                        onClick={event => {
                          event.stopPropagation();
                          cleanModalState();
                          if (
                            image.mime_type === 'application/x-msdownload' ||
                            image.mime_type === 'application/x-msdos-program'
                          ) {
                            toast({
                              message:
                                'O tipo de arquivo não pode ser selecionado.',
                              type: 'warning',
                            });
                          } else {
                            if (image.categories.length === 0) {
                              addCategory(image);
                            } else {
                              setFileComplementary([
                                ...fileComplementary,
                                image,
                              ]);
                            }
                            setCollectionModalOpen(false);
                          }
                        }}
                      >
                        <CardActionArea>
                          <styles.spanType>{image.type}</styles.spanType>
                          <CardMedia
                            component="img"
                            height="140"
                            width="150"
                            image={image.reference}
                          />
                          <styles.span>{image.original_name}</styles.span>
                        </CardActionArea>
                        <styles.rowIcon>
                          <IconButton
                            color={'info'}
                            size={'small'}
                            onClick={event => {
                              event.stopPropagation();
                              downloadFile(
                                image.reference,
                                image.original_name,
                              );
                              cleanModalState();
                              setCollectionModalOpen(false);
                            }}
                          >
                            <FileDownload fontSize="medium" />
                          </IconButton>
                          <IconButton
                            color={'info'}
                            size={'small'}
                            onClick={event => {
                              event.stopPropagation();
                              setSkill({
                                ...refSkill.current,
                                content: undefined,
                              });
                              cleanModalState();
                              editCategory(image);
                              setCollectionModalOpen(false);
                            }}
                          >
                            <ModeEdit />
                          </IconButton>
                          <IconButton
                            color={'error'}
                            size={'small'}
                            onClick={event => {
                              event.stopPropagation();
                              deleteImage(image);
                              setCollectionModalOpen(false);
                            }}
                          >
                            <DeleteForever />
                          </IconButton>
                        </styles.rowIcon>
                      </Card>
                    );
                  })}
                </styles.row>
                <styles.row>
                  {images.slice(images.length / 2, images.length).map(image => {
                    return (
                      <Card
                        key={image.reference}
                        sx={{ width: 150, margin: 'auto', marginTop: '0.5rem' }}
                        onClick={event => {
                          event.stopPropagation();
                          cleanModalState();
                          if (
                            image.mime_type === 'application/x-msdos-program' ||
                            image.mime_type === 'application/x-msdos-program'
                          ) {
                            toast({
                              message:
                                'O tipo de arquivo não pode ser selecionado.',
                              type: 'warning',
                            });
                          } else {
                            if (image.categories.length === 0) {
                              addCategory(image);
                            } else {
                              setFileComplementary([
                                ...fileComplementary,
                                image,
                              ]);
                            }
                            setCollectionModalOpen(false);
                          }
                        }}
                      >
                        <CardActionArea>
                          <styles.spanType>{image.type}</styles.spanType>
                          <CardMedia
                            component="img"
                            height="140"
                            width="150"
                            image={image.reference}
                          />
                          <styles.span>{image.original_name}</styles.span>
                          <styles.rowIcon>
                            <IconButton
                              color={'info'}
                              size={'small'}
                              onClick={event => {
                                event.stopPropagation();
                                downloadFile(
                                  image.reference,
                                  image.original_name,
                                );
                                cleanModalState();
                                setCollectionModalOpen(false);
                              }}
                            >
                              <FileDownload fontSize="medium" />
                            </IconButton>
                            <IconButton
                              color={'info'}
                              size={'small'}
                              onClick={event => {
                                event.stopPropagation();
                                cleanModalState();
                                editCategory(image);
                                setCollectionModalOpen(false);
                              }}
                            >
                              <ModeEdit />
                            </IconButton>
                            <IconButton
                              color={'error'}
                              size={'small'}
                              onClick={event => {
                                event.stopPropagation();
                                deleteImage(image);
                                setCollectionModalOpen(false);
                              }}
                            >
                              <DeleteForever />
                            </IconButton>
                          </styles.rowIcon>
                        </CardActionArea>
                      </Card>
                    );
                  })}
                </styles.row>
              </>
            ) : (
              <>Carregando imagens ...</>
            )}

            <styles.row>
              <div style={{ margin: 'auto', marginTop: '0.5rem' }}>
                <Button
                  variant="contained"
                  disabled={imagePage === 1}
                  onClick={event => {
                    event.stopPropagation();

                    if (imagePage > 0) {
                      setLoading(true);
                      const params: IGetFilesParams = {
                        offset: imagePage - 1,
                        limit: 6,
                        permission,
                      };
                      if (typeFilter.length) params['type'] = typeFilter;
                      //TODO: VERIFICAR SE ESSE IF É NECESSÁRIO
                      if (filter !== '') {
                        params['search'] = filter;
                        getFiles(params).then(images => {
                          setImages(images?.data);
                          setImagePage(imagePage - 1);
                        });
                      } else {
                        getFiles(params).then(images => {
                          setImages(images?.data);
                          setImagePage(imagePage - 1);
                        });
                      }
                      setLoading(false);
                    }
                  }}
                >
                  {'<'}
                </Button>
                <Button variant="contained">{imagePage}</Button>
                <Button
                  variant="contained"
                  onClick={event => {
                    setLoading(true);
                    const params: IGetFilesParams = {
                      offset: imagePage + 1,
                      limit: 6,
                      permission,
                    };

                    if (typeFilter.length) params['type'] = typeFilter;

                    if (filter !== '') {
                      params['search'] = filter;
                      getFiles(params)
                        .then(images => {
                          if (images?.data.length > 0) {
                            setImages(images?.data);
                            setImagePage(imagePage + 1);
                          } else {
                            toast({
                              message: 'última página',
                              type: 'warning',
                            });
                          }
                        })
                        .catch(error => {
                          toast({
                            message:
                              'É necessario escolher apenas uma categoria filha.',
                            type: 'error',
                          });
                        });
                    } else {
                      getFiles(params)
                        .then(images => {
                          if (images?.data.length > 0) {
                            setImages(images?.data);
                            setImagePage(imagePage + 1);
                          } else {
                            toast({
                              message: 'última página',
                              type: 'warning',
                            });
                          }
                        })
                        .catch(error => {
                          toast({
                            message: 'Algo deu errado',
                            type: 'warning',
                          });
                        });
                    }
                    setLoading(false);
                    event.stopPropagation();
                  }}
                >
                  {'>'}
                </Button>
              </div>
            </styles.row>
          </styles.column>
        </Box>
      </Modal>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ alignItems: 'center', justifyContent: 'center' }}
      >
        <Box style={getModalStyle()}>
          <form>
            <AppBar
              position="static"
              style={{ marginBottom: '1rem' }}
              sx={{ bgcolor: '#008d4c', height: '3.5rem' }}
            >
              <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  Mídia
                </Typography>
                <Button onClick={() => setModalOpen(false)} color="inherit">
                  x
                </Button>
              </Toolbar>
            </AppBar>

            <styles.Label>
              <p>Arquivo/Referência *</p>
            </styles.Label>
            {modalPreview !== undefined ? (
              <styles.ContentImg>
                <styles.Preview
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  src={modalPreview}
                />
              </styles.ContentImg>
            ) : (
              <styles.ContentImg>
                <ArticleIcon
                  color="disabled"
                  fontSize="large"
                  sx={{ height: '6em' }}
                  style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: '6em',
                  }}
                />
              </styles.ContentImg>
            )}
            <styles.Label>Nome Arquivo</styles.Label>
            <styles.ContentSeletor>
              <Box
                component="div"
                sx={{
                  '& .MuiTextField-root': { m: 0, width: '100%' },
                }}
              >
                <Controller
                  name="file_name"
                  control={control}
                  rules={{ required: true }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <>
                        <styles.InputText
                          disabled={isEditing}
                          name="file_name"
                          value={value}
                          onChange={event => {
                            onChange(event.target.value);
                            setFileName(event.target.value);
                          }}
                          required
                          id="outlined-required"
                        />
                        {error && (
                          <styles.spanRequired
                            style={{ color: 'red', marginBottom: '0.5rem' }}
                          >
                            *Campo obrigatório
                          </styles.spanRequired>
                        )}
                      </>
                    );
                  }}
                />
              </Box>
            </styles.ContentSeletor>
            <styles.Label>Tooltip Arquivo</styles.Label>
            <styles.ContentSeletor>
              <Box
                component="div"
                sx={{
                  '& .MuiTextField-root': { m: 0, width: '100%' },
                }}
              >
                <Controller
                  name="tooltip"
                  control={control}
                  rules={{ required: true }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <styles.InputText
                        name="tooltip"
                        value={value}
                        onChange={event => {
                          onChange(event.target.value);
                          setFileToolTip(event.target.value);
                        }}
                      />
                      {error && (
                        <styles.spanRequired
                          style={{ color: 'red', marginBottom: '0.5rem' }}
                        >
                          *Campo obrigatório
                        </styles.spanRequired>
                      )}
                    </>
                  )}
                />
              </Box>
            </styles.ContentSeletor>
            {files && (
              <CategoryDropDown
                setBackCategories={setBackCategories}
                backCategories={backCategories}
                formProps={skill}
              />
            )}
            <Box textAlign="center">
              <ButtonGroup
                variant="contained"
                size="small"
                style={{ margin: 'auto', marginBottom: '1rem' }}
              >
                <label htmlFor="contained-button-file">
                  <Input
                    accept="*/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={(event: any) => {
                      if (
                        event.target.files[0].type ===
                          'application/x-msdownload' ||
                        event.target.files[0].type ===
                          'application/x-msdos-program' ||
                        event.target.files[0].type ===
                          'application/octet-stream'
                      ) {
                        toast({
                          message:
                            'O tipo de arquivo não pode ser selecionado.',
                          type: 'warning',
                        });
                      } else {
                        setFiles(event.target.files);
                        const reader = new FileReader();
                        reader.readAsDataURL(event.target.files[0]);
                        reader.onloadend = (e: any) => {
                          setModalPreview(
                            reader.result != undefined ? reader.result : 'null',
                          );
                        };
                      }
                    }}
                  />
                  <Button
                    disabled={isEditing}
                    variant="contained"
                    size="small"
                    component="span"
                    startIcon={<Attachment />}
                    style={{
                      backgroundColor: '#00acd6',
                    }}
                  >
                    <span>Escolher arquivo</span>
                  </Button>
                </label>
                <Button
                  startIcon={<Save />}
                  disabled={!files || category.length === 0}
                  onClick={handleSubmit(onSubmit)}
                  variant="contained"
                  size="small"
                  style={{
                    backgroundColor: '#008d4c',
                    marginLeft: 10,
                  }}
                >
                  <span>Salvar</span>
                </Button>
              </ButtonGroup>
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
